import { DateTime } from "luxon";
import {
  DocumentAttributes,
  MacroElectionAttributes,
  TickerAttributes,
} from "./types/cms";

export const parseDatasetTag = (
  tag: string | string[] | undefined,
  translations: { [key: string]: string } = {},
) => {
  if (!tag) return "";

  if (typeof tag === "object") {
    const output = [];
    for (const t of tag)
      output.push(
        translations[t] || t.charAt(0) + t.substring(1).toLowerCase(),
      );
    return output.join(", ");
  }

  if (translations[tag]) return translations[tag];

  const splitTag = tag.split("_");
  let output = "";

  for (const word of splitTag) {
    output += word.charAt(0) + word.substring(1).toLowerCase() + " ";
  }
  return output.trim();
};

export const getFormattedDate = (report_date: string | undefined) => {
  const reportDate = report_date
    ? DateTime.fromISO(report_date).toFormat("dd MMM yyyy")
    : "";
  return reportDate;
};

export const getFormattedReportMetada = (
  report: DocumentAttributes,
  associated_ticker?: TickerAttributes,
) => {
  const {
    report_id,
    ticker,
    company_name,
    report_date,
    report_start_date,
    jsondocument,
    logo_url,
  } = report;
  const { company_name: ticker_company_name, logo_url: ticker_logo_url } =
    associated_ticker || {};
  const companyName = `${ticker_company_name || company_name || ""}`;
  const reportDate = getFormattedDate(report_date);
  const reportingPeriodTitle =
    report_start_date && report_date
      ? `(${DateTime.fromISO(report_start_date).toFormat(
          "MMM yyyy",
        )} - ${DateTime.fromISO(report_date).toFormat("MMM yyyy")})`
      : "";
  const reportingPeriod =
    report_start_date && report_date
      ? `AI Summary : ${DateTime.fromISO(report_start_date).toFormat(
          "dd MMM yyyy",
        )} - ${DateTime.fromISO(report_date).toFormat("dd MMM yyyy")}`
      : "";
  const title = `${companyName} AI Insights ${reportingPeriodTitle}`;
  const overview = jsondocument?.overview?.content || "";
  const encodedReportId = encodeURIComponent(report_id);
  return {
    ticker: ticker || "",
    companyName,
    reportDate,
    title,
    reportingPeriod,
    overview,
    encodedReportId,
    logo_url: ticker_logo_url || logo_url,
    jsondocument,
  };
};

export type PeriodKey =
  | "last_week"
  | "last_month"
  | "last_3_months"
  | "last_6_months"
  | "year_to_date"
  | "all_time";

export const getPeriodStartDate = (periodKey: PeriodKey): Date => {
  const date = new Date();
  const currentYear = date.getFullYear();

  switch (periodKey) {
    case "last_week": {
      const lastSunday = new Date(
        date.setDate(date.getDate() - date.getDay() - 1),
      );
      lastSunday.setHours(0, 0, 0, 0); // Start of the day
      return lastSunday;
    }
    case "last_month": {
      const firstDayLastMonth = new Date(
        date.getFullYear(),
        date.getMonth() - 1,
        1,
      );
      return firstDayLastMonth;
    }
    case "last_3_months": {
      const firstDayLast3Month = new Date(
        date.getFullYear(),
        date.getMonth() - 3,
        1,
      );
      return firstDayLast3Month;
    }
    case "last_6_months": {
      const firstDayLast6Month = new Date(
        date.getFullYear(),
        date.getMonth() - 6,
        1,
      );
      return firstDayLast6Month;
    }
    case "year_to_date": {
      const firstDayOfYear = new Date(currentYear, 0, 1); // January 1st of current year
      return firstDayOfYear;
    }
    case "all_time":
      return new Date(0); // Unix epoch time
    default:
      return date; // Fallback to current date if no match found
  }
};

export type SortingMethod = "market_cap" | "report_date";

export const getSortingParams = (sort: SortingMethod) => {
  switch (sort) {
    case "market_cap":
      return ["market_cap:desc", "ticker:asc"];
    case "report_date":
      return ["report_date:desc", "market_cap:desc", "ticker:asc"];
    default:
      return ["market_cap:desc", "ticker:asc"];
  }
};

export const getDocumentsQueryParamsField = (sort: SortingMethod) => {
  switch (sort) {
    case "market_cap":
      return ["ticker", "company_name", "market_cap", "logo_url", "featured"];
    case "report_date":
      return [
        "report_id",
        "ticker",
        "company_name",
        "report_date",
        "report_start_date",
        "jsondocument",
        "logo_url",
      ];
    default:
      return ["ticker", "company_name", "market_cap", "logo_url", "featured"];
  }
};

export const getDocumentsQueryParamsPopulateFields = (sort: SortingMethod) => {
  switch (sort) {
    case "market_cap":
      return {
        documents: {
          fields: [
            "report_id",
            "ticker",
            "company_name",
            "report_date",
            "report_start_date",
            "jsondocument",
            "logo_url",
          ],
          sort: ["report_date:desc"],
        },
      };
    case "report_date":
      return {
        associated_ticker: {
          fields: [
            "ticker",
            "company_name",
            "market_cap",
            "logo_url",
            "featured",
          ],
        },
      };
    default:
      return {
        documents: {
          fields: ["report_id", "report_date", "jsondocument"],
          sort: ["report_date:desc"],
        },
      };
  }
};

export const getMacroDocumentsListQueryParamsFields = () => {
  const macroDocumentFields = [
    "report_id",
    "title",
    "topic",
    "region",
    "report_date",
    "byline",
  ];
  return macroDocumentFields;
};

export const getMacroDocumentsQueryParamsFields = () => {
  const macroDocumentFields = [
    "report_id",
    "title",
    "topic",
    "region",
    "report_date",
    "byline",
    "image_file",
    "image_name",
    "summary",
    "detailed_insight",
  ];
  return macroDocumentFields;
};

type SortableItem = {
  [key: string]: any;
};
export const sortArrayByProperty = <T extends SortableItem>(
  array: T[],
  property: keyof T,
  isAscending = true,
): T[] => {
  return array.sort((a, b) => {
    let valueA = a[property];
    let valueB = b[property];

    if (typeof valueA === "string" && typeof valueB === "string") {
      valueA = valueA.toLowerCase();
      valueB = valueB.toLowerCase();
    }

    if (valueA < valueB) {
      return isAscending ? -1 : 1;
    }
    if (valueA > valueB) {
      return isAscending ? 1 : -1;
    }
    return 0;
  });
};

export interface PredictionResult {
  [key: string]: ElectionPrediction;
}
export interface ElectionPrediction {
  name: string;
  latestPercentage: number;
  change: number;
  latestDate: string;
}
export const getElectionPrediction = (
  info?: MacroElectionAttributes,
): PredictionResult => {
  const prediction = info?.data;
  const result: PredictionResult = {};

  if (prediction?.length) {
    prediction.forEach(({ x, y, name }) => {
      if (y.length >= 8) {
        const latestDate = x[x.length - 1];
        // const latestPercentage = (y[y.length - 1] * 100).toFixed(2);
        const latestPercentage = y[y.length - 1];
        const change = y[y.length - 1] - y[y.length - 8];

        result[name] = {
          name,
          latestPercentage,
          change,
          latestDate,
        };
      }
    });
  }

  return result;
};

// TODO: get placeholder image
import donald_trump_img from "../src/assets/election_images/donald_trump.png";
import kamala_harris_img from "../src/assets/election_images/kamala_harris.png";
const candidateImages: any = {
  "Donald Trump": donald_trump_img,
  "Kamala Harris": kamala_harris_img,
};

export const getCandidateImage = (name: string) => {
  return candidateImages[name];
};

export const getPredictionChangeDisplay = (change: number) => {
  let changeDisplay = {
    color: "text-gray-600",
    text: "0.00%",
    // icon: (<ArrowsUpDownIcon className='h-4 w-4' />)
  };

  if (change > 0) {
    changeDisplay = {
      color: "text-green-500",
      text: `+ ${(change * 100).toFixed(2)}%`,
      // icon: (<ArrowUpIcon className='h-5 w-5 text-green-500' />)
    };
  } else if (change < 0) {
    changeDisplay = {
      color: "text-red-500",
      text: `- ${Math.abs(change * 100).toFixed(2)}%`,
      // icon: (<ArrowDownIcon className='h-5 w-5 text-red-500' />)
    };
  }

  return changeDisplay;
};

export const getThemesDocumentsListQueryParamsFields = () => {
  const themesDocumentListFields = [
    "id",
    "basket",
    "basket_name",
    "report_id",
    "report_date",
    "summary",
  ];
  return themesDocumentListFields;
};

export const getThemesDocumentsQueryParamsFields = () => {
  const themesDocumentFields = [
    "report_id",
    "title",
    "report_date",
    "basket",
    "basket_name",
    "summary",
    "market_volatility",
    "republican_sweep_benefits",
    "tariff_impact",
    "fed_policy_adjustments",
    "economic_policy_effects",
    "tech_sector_impact",
    "immigration_policy_changes",
    "catalysts",
    "change_in_market_trends",
    "competitive_landscape",
    "growth_drivers",
    "key_players",
    "regulatory_environment",
    "risks_and_challenges",
    "technological_advancements",
  ];
  return themesDocumentFields;
};

export const getCommoditiesDocumentsListQueryParamsFields = () => {
  const commoditiesDocumentFields = [
    "report_id",
    "title",
    "topic",
    "commodity",
    "report_date",
    "byline",
  ];
  return commoditiesDocumentFields;
};

export const getCommoditiesDocumentsQueryParamsFields = () => {
  const commoditiesDocumentFields = [
    "report_id",
    "title",
    "topic",
    "commodity",
    "report_date",
    "byline",
    "summary",
    "content",
  ];
  return commoditiesDocumentFields;
};
